@import 'src/styles/variables';
@import 'src/styles/mixins';

.mainContainer {
  @include verticalGap(24px);
}

.title {
  @include h1;
  text-align: center;
}

.labelContainer {
  color: $black100;
  text-align: center;
  line-height: 28px;
  @include verticalGap(16px);
}

.buttonsContainer {
  @include verticalGap(16px);
}
