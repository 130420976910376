@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  width: 100%;
  z-index: 100;
  position: fixed;
  bottom: 0;
  overflow-y: auto;
  max-height: 100%;
  left: 50%;
  border-radius: 24px 24px 0 0;
  padding: 24px;
  background-color: $white;
  box-sizing: border-box;
  transform: translate(-50%, 0);

  @include sm {
    width: 450px;
    height: fit-content;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 24px;
  }
}

.centeredModal {
  top: 50%;
  left: 50%;
  overflow: hidden;
  height: fit-content;
  transform: translate(-50%, -50%);
  border-radius: 24px;
}

.title {
  @include h2;
  text-align: center;
  margin-bottom: 24px;
}

.closeIcon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 20px;
  right: 20px;

  path {
    fill: $black100;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
